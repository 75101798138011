  <template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <div class="column is-12 "><PageTitle :title="formTitle"/></div>
    <div class="column is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop doodle-display-box" v-show="!inProgress">
      <div class="field">
        <div class="control has-icons-right has-icons-left">
          <input  type='text' class="input" name="search" v-model="searchTerm" v-on:input="searchStudentGroup" autocomplete="off">
          <span class="icon is-small is-left">
            <font-awesome-icon icon="search"/>
          </span>
        </div>
      </div>
      <div class="margin-top-10px margin-bottom-10px">
        <DoodleMessage :message="successMessage" :message-class="messageClass"/>
      </div>
      <div class="has-text-centered" v-if="!searchTerm">Search with a name in the above box</div>
      <div class="has-text-centered" v-if="searchTerm && searchResult.length<1">No match found.</div>
      <div class="has-text-centered" v-if="searchTerm && searchResult.length>0">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
          <tbody>
          <tr v-for="sg in searchResult" v-bind:key="sg.name">
            <td>{{sg.name}}</td>
            <td><button class="button is-small is-success has-text-weight-semibold" @click="includeStudentGroup(sg)">Add</button></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Spinner v-show="inProgress" :message="inProgressMessage"/>
    <div class="has-text-centered margin-top-20px">
      <button class="button has-text-weight-semibold" type="button" @click="$refs.sideBar.emitToggle()">Close</button>
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import SideBar from '../Utils/SideBar'
import { eventBus } from './../../assets/javascript/event-bus'
import PageTitle from '../PageTitle'
import DoodleMessage from '../Utils/DoodleMessage'
import Spinner from '../Utils/Spinner'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'guruIncludeStudentGroupInX',
  mixins: [formMixin],
  inject: ['$validator'],
  components: { Spinner, DoodleMessage, PageTitle, SideBar },
  props: ['formTitle', 'inProgressMessage', 'actionText', 'notifyEvent'],
  data: function () {
    return {
      masterData: [],
      searchTerm: null,
      searchResult: [],
      inProgress: false,
      successMessage: null,
      messageClass: 'has-text-success'
    }
  },
  mounted () {
    this.getStudentGroups()

    eventBus.$on(GURU_EVENTS.STUDENT_GROUP_INCLUDED_NOTIFICATION, (sg) => {
      this.inProgress = false
      this.successMessage = 'Students Added from the Group!'
      this.messageClass = 'has-text-success'

      this.masterData.splice(this.masterData.indexOf(sg), 1)
      this.searchResult.splice(this.searchResult.indexOf(sg), 1)

      this.$_.delay(() => {
        this.successMessage = null
      }, 5000)
    })

    eventBus.$on(GURU_EVENTS.STUDENT_GROUP_INCLUDE_FAILED_NOTIFICATION, () => {
      this.inProgress = false
      this.successMessage = 'Unable to Add, Please try again!'
      this.messageClass = 'has-text-danger'
    })
  },
  beforeDestroy () {
    eventBus.$off(GURU_EVENTS.STUDENT_GROUP_INCLUDED_NOTIFICATION)
    eventBus.$off(GURU_EVENTS.STUDENT_GROUP_INCLUDE_FAILED_NOTIFICATION)
  },
  methods: {
    includeStudentGroup (sg) {
      this.inProgress = true
      this.successMessage = null
      eventBus.$emit(this.notifyEvent, sg)
    },
    searchStudentGroup () {
      let st = this.searchTerm.toLowerCase()
      this.searchResult = []
      window._.forEach(this.masterData, (o) => {
        if (this.searchResult.length > 7) {
          return false
        }
        if (o.name.toLowerCase().includes(st)) {
          this.searchResult.push(o)
        }
      })
    },
    getStudentGroups () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/studentGroups',
        data: {
          instituteCode: this.$route.params.instituteCode
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          this.masterData = data.studentGroups
        },
        jdaEvent: 'guru-get-student-groups',
        jdaLabel: ''
      })
    }
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">

</style>
